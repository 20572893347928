$color-grey: #9E9E9E;
$color-dark-grey: #717070;
$color-blue: #0070C0;
$color-black: #000000;
$color-body: #FBFBFD;
$color-border: #EEEEEE;
$color-white: #FFFFFF;
$color-green: #149d52;
$color-red: #dc3545;
$color-light-blue: #ebf9f4;
$color-light-red: #ffd6db;
$color-disabled: #F2F2F2;
$color-mild-blue: #e5f1f9;
$color-golden: #F7BA00;
$color-team_a: #0070C0;
$color-team_b: #5DB5F4;
$color-team_c: #24D474;
$color-team_d: #D4D4D4;
$color-dark-red: #e20f23;
$color-dark-orange: #ff8c00;
$color-dark-brown: #820000;
$color-sea-blue: #82C3EC;
$color-ice-green: #439A97;
$color-track: #f1f1f1;
$color-unread: #f4f5f6;