.theme-popup {
    .times-icon {
        svg {
            cursor: pointer;
        }
    }

    &.upload-csv-popup {
        label {
            cursor: pointer;
        }

        .download-csv {
            font-size: 12px;
            text-decoration: underline;
            color: $color-blue;
            cursor: pointer;
        }

        .upload-box {
            border-radius: 10px;
            border: 2px dashed $color-black;
            width: 100%;
            height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 225px;
                text-align: center;
            }

            .img-wrapper {
                width: 100px;
                height: 100px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &.edit-profile-popup {
        .profile-wrapper {
            min-width: 100px;
            width: 100px;
            height: 100px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::before {
                background-color: rgba(0, 0, 0, .176);
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='30' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.419 14.069c-.055 2.57-2.053 4.638-4.434 4.589-2.46-.05-4.411-2.126-4.372-4.647.039-2.554 2.037-4.638 4.426-4.613 2.444.033 4.434 2.15 4.38 4.671Z' fill='%23fff'/%3E%3Cpath d='M29.567 7.371c-.328-.914-.89-1.606-1.702-2.076-.632-.37-1.32-.453-2.022-.478a53.278 53.278 0 0 0-2.295 0c-.289.008-.476-.099-.64-.354-.445-.725-.89-1.458-1.35-2.175C20.822 1.134 19.784.54 18.488.525a245.24 245.24 0 0 0-6.963 0c-1.179.016-2.17.502-2.896 1.516-.554.774-1.015 1.614-1.522 2.422-.164.255-.352.354-.64.354-1 .008-2.007-.041-3.006.05C1.854 5.015.292 6.555.3 8.434c.016 4.474.008 8.955.008 13.43 0 .337.024.683.125 1.012.476 1.55 1.881 2.62 3.474 2.62 3.567.009 7.127 0 10.695 0H26.07c.702 0 1.373-.156 1.967-.56.937-.642 1.577-1.508 1.663-2.735V8.966c0-.533.05-1.094-.133-1.595ZM15 22.366c-4.38.008-7.931-3.79-7.9-8.38.031-4.588 3.575-8.304 7.915-8.287 4.372.016 7.94 3.79 7.9 8.378-.03 4.59-3.512 8.28-7.915 8.289Zm10.742-12.227a9.985 9.985 0 0 1-1.406 0 1.064 1.064 0 0 1-.999-1.104c.016-.601.453-1.054 1.062-1.07.217-.009.434 0 .651 0 .003 0 .005.001.005.003 0 .003.002.005.004.005.21 0 .418-.009.628 0 .57.032.984.47 1.007 1.062.023.569-.383 1.063-.952 1.104Z' fill='%23fff'/%3E%3C/svg%3E");
                background-position: 50%;
                background-repeat: no-repeat;
                border-radius: 10px;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
            }
        }
    }

    &.notification-popup {
        .modal-content {
            max-width: 432px;
            transform: translateX(55px);
        }

        .modal-dialog {
            margin-right: 10px;
        }

        .notification-wrapper {
            height: 450px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .mark-read{
                color: $color-blue;
                cursor: pointer;
            }

            .box-wrapper {
                border-radius: 10px;
                padding: 15px;

                &.notification-unread {
                    background-color: $color-unread;
                }
            }

            .wrapper {
                height: 100%;
            }

            ul {
                padding: 0;
                cursor: pointer;

                li {
                    list-style: none;
                    margin-bottom: 15px;

                    p {
                        font-size: 14px;
                    }

                    .icon-wrapper {
                        min-width: 65px;
                        width: 65px;
                        height: 65px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $color-mild-blue;
                        border-radius: 50%;
                        overflow: hidden;

                        svg {
                            font-size: 1.4rem;
                            color: $color-blue;
                        }
                    }
                }
            }
        }
    }

    &.add-competencyand-er-popup {
        .form-check {
            display: flex;
            align-items: center;

            label {
                margin-left: 10px;
            }
        }
    }
}